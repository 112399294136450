.App {
  text-align: center;
  height: 100vh;
    width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}

.header-text{
  margin: 0px;
  font-size: 3.5em
}

.header-image {
  width: 15em;
  border: solid 5px;
  border-color: #ffa500;
  border-radius: 50%;
  margin-bottom: 2vh;
}

.Intro-Text{
  font-size: 15px
}

.Regular{
  font-family: 'Raleway';
}


.Bold {
  font-family: 'Righteous';
  font-size: 1.2em;
}


.Home-Button-Group{
  margin-right: 1vw;
  margin-left: 1vw;
  font-size: 1em;
  background-color: 'white';
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 0px 0px 2px 2px #ffa5004f;
  padding: 1vw;
}



.School-Link{
  color: 'orange'
}


.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5vw;
  background-color: 'white';
  padding: 5vw;
}

.grid-container > div {
  text-align: center;
  font-size: 30px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
